<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getItems()"
      :search="search"
      :loading="!items"
      :loading-text="$t('loading')"
      sort-by="name,code,suff"
      class="elevation-1"
      :items-per-page="20"
      :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-t="'kits'"></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-btn style="margin-right:5px;" color="info" dark class="mb-2" @click="showActive = !showActive">{{ !showActive? this.$t('hideParam', {param:$t('inactive')}) : $t('showAll') }}</v-btn>
          <v-btn color="primary" dark class="mb-2" to="/kits/new" v-t="'add'"></v-btn>
          <v-dialog v-model="dialogDelete" max-width="400">
            <v-card class="mx-auto" color="white">
              <v-card-title>
                <span class="title font-weight-light">{{ $t('deleteParam', {param:$t('kit')}) }}</span>
              </v-card-title>
              <v-card-text class="headline font-weight-bold">{{ $t('deleteText', {param:$t('kit')}) }}</v-card-text>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-row align="center" justify="end">
                    <v-icon class="mr-1" color="success" @click="deleteKitConfirm">mdi-check</v-icon>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1" color="error" @click="closeDelete">mdi-close</v-icon>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <BooleanIcon :booleanValue="item.active" />
      </template>
      <template v-slot:item.obsolete="{ item }">
        <BooleanIcon :booleanValue="item.obsolete" />
      </template>
      <template v-slot:item.itemFamilyId="{ item }">
        {{ getFamilyName(item.familyId) }}
      </template>
      <template v-slot:item.itemGroupId="{ item }">
        {{ getGroupName(item.itemGroupId) }}
      </template>
      <template v-slot:item.itemCollectionId="{ item }">
        {{ getCollectionName(item.itemCollectionId) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn dense icon raised elevation="2" color="secondary" :to="'kits/'+item.id" class="mx-2"><v-icon small> mdi-pencil </v-icon></v-btn>
        <v-btn dense icon raised elevation="2" color="error" @click="deleteItem(item)"><v-icon small> mdi-delete </v-icon></v-btn>
      </template>
      <template v-slot:no-data>
        <NoDataImg/>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import BooleanIcon from '@/components/app/BooleanIcon';
import { call, sync } from "vuex-pathify";
import NoDataImg from '../../components/NoDataImg.vue';

export default {
  components: {
    BooleanIcon,
    NoDataImg
  },
  data: () => ({
    dialogDelete: false,
    showActive: true,
    search: "",
    editedIndex: -1,
    editedKit: {},
    defaultItem: {}
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('newParam', {param:this.$t('kit')}) : this.$t('editParam', {param:this.$t('kit')});
    },
    headers(){
      return [
        {text: this.$t('active'), align: "center", value: "active"},
        {text: this.$t('obsolete'), align: "center", value: "obsolete"},
        {text: this.$t('code'), align: "start", value: "code",},
        {text: this.$t('description'), value: "description"},
        {text: this.$t('family'), value: "itemFamilyId"},
        {text: this.$t('actions'), value: "actions", sortable: false, align: "center"}
      ]
    },
    items: sync("itemKits/itemKits"),
    families: sync("families/families"),
    groups: sync("groups/groups"),
    collections: sync("collections/collections"),
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {

    initialize() {
      // get the kits from BE - without forcing the download
      this.initItemKitCollection();
      //
      this.initFamilyCollection();
      this.initGroupCollection();
      this.initCollectionCollection();
    },

    getItems() {
      return this.showActive
        ? this.items.filter(x => !!x.active)
        : this.items
    },  

    deleteItem(item) {
      this.editedKit = item;
      this.dialogDelete = true;
    },

    deleteKitConfirm() {
      this.apiItemKitIdDelete(this.editedKit.id)
      .then(this.closeDelete);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedKit = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getFamilyName(id) { return (this.families.find(el => el.id === id) || {}).description || ''; },

    getGroupName(id) { return (this.groups.find(el => el.id === id) || {}).description || ''; },

    getCollectionName(id) { return (this.collections.find(el => el.id === id) || {}).description || ''; },

    ...call("itemKits/*"),
    ...call("families/*"),
    ...call("groups/*"),
    ...call("collections/*"),

  },
};
</script>